<script setup>
import { useVModel } from "@vueuse/core";
import { nanoid } from "nanoid";
import { computed, inject, useAttrs } from "vue";
import { cn } from "~/utils/cn";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  label: { type: String, default: null },
  modelValue: { type: [Boolean, Array, Set, String, Number], default: null },
  value: { type: [String, Number], default: null },
  hideLabel: { type: Boolean, default: false },
});

// Can include form, table, modal
const renderContext = inject("renderContext", []);
const emits = defineEmits(["update:modelValue"]);

const { class: wrapperClass, ...attrs } = useAttrs();
const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
});
const id = computed(() => attrs.id || attrs.name || nanoid(8));
const name = computed(() => attrs.name || attrs.id);
const disabled = computed(() => (attrs.disabled || attrs.disabled === "" ? true : undefined));
</script>

<template>
  <div v-tooltip="hideLabel ? label : ''" :class="cn('flex items-center', renderContext.includes('form') && 'my-4', wrapperClass)">
    <input
      v-bind="attrs"
      :id="id"
      v-model="modelValue"
      :value="value"
      :name="name"
      :disabled="disabled"
      type="radio"
      class="h-4 w-4 rounded-full border-gray-300 text-blue-600 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-100"
    />
    <label v-if="label && !hideLabel" :for="id" class="ml-2 text-sm font-medium leading-none text-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
      {{ label }}
    </label>
  </div>
</template>
